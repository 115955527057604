import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/public.scss";
import './assets/css/fontFamily/text.scss'
// import Vconsole from "vconsole"; //main.js
// const vConsole = new Vconsole(); //main.js

createApp(App).use(store).use(router).mount("#app");
