import axios from "axios";

function handleError(code, msg) {
  Message({
    message: msg,
    type: "error",
    duration: 5 * 1000,
  });
}

const instance = axios.create({
  validateStatus: function (status) {
    return status >= 200 && status < 300;
  },
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000,
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.data.success === false) {
      console.log("========", response);
    }
    if (response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    console.log("======error", error);
    let errMsg = error.message;
    if (error.response.data.message !== undefined) {
      errMsg = error.response.data.message;
    }
    return Promise.reject(error);
  }
);

export default instance;
