import request from "./request";
const baseUrl = "/v1/base/list";
const siteUrl = "/v1/site/config/get";
const postUrl = "/v1/post/get";

const requestApi = (url, params) => {
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: "GET",
      params: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const baseService = (params) => {
  return requestApi(baseUrl, params);
};
export const postDetailService = (params) => {
  return requestApi(postUrl, params);
};
export const siteConfigService = (params) => {
  return requestApi(siteUrl, params);
};
