import { createRouter, createWebHistory } from 'vue-router';
import layout from '../views/layout/index.vue';
import layout_en from '../views/layout_en/index.vue';
import * as dd from 'dingtalk-jsapi';
const routes = [
  {
    path: '/',
    component: layout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home/index.vue'),
      },
    ],
  },
  {
    path: '/en',
    component: layout_en,
    children: [
      {
        path: '/en',
        name: 'English',
        component: () => import('@/views/English/index.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const ddTitle = (title) => {
  //钉钉内置浏览器修改标题调用
  dd.ready(() => {
    dd.biz.navigation.setTitle({
      title: title,
    });
  });
};

router.beforeEach((to, from, next) => {
  //英文页面显示英文标题 中文页面显示中文标题
  let title = '';
  if (to.path == '/en') {
    title = 'JURUI';
    document.title ? (document.title = title) : ddTitle(title);
  } else {
    title = '聚睿';
    document.title ? (document.title = title) : ddTitle(title);
  }
  next();
});

export default router;
