<template>
  <div class="html">
    <div
      class="menu"
      :style="isFixed ? 'background:#000;' : ''"
    >
      <div class="menu_container">
        <div
          class="switch"
          @click.stop="menuActiveClick"
          @mouseenter="isHoverSwitch = true"
          @mouseleave="isHoverSwitch = false"
        >
          <span
            v-show="!menu"
            :style="isHoverSwitch ? 'opacity: 1;' : 'opacity: 0.5;'"
          ></span>
          <span
            v-show="!menu"
            :style="isHoverSwitch ? 'opacity: 1;' : 'opacity: 0.5;'"
          ></span>
          <span
            v-show="!menu"
            :style="isHoverSwitch ? 'opacity: 1;' : 'opacity: 0.5;'"
          ></span>
        </div>
        <div class="menu_container-logo">
          <div>
            <img
              src="@/assets/image/jurui.png"
              alt=""
            />
          </div>
        </div>
        <span
          class="pointer"
          @click="toUrl"
        >English</span>
        <div
          class="menu__container__nav"
          v-show="menu"
          @mouseleave="menu = false"
        >
          <ul>
            <li
              class="menu__container__nav_item"
              v-for="(item, index) of nav.itemList"
              :key="index"
              @click="navClick(item.target)"
            >
              {{ item.label }}
            </li>
            <li
              class="menu__container__nav_item end_li"
              @click="toUrl"
            >English</li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="content"
      @click="menu = false"
    >
      <router-view :target="navTarget" />
    </div>
    <div class="footer">
      <div
        class="footer-title"
        ref="footerRef"
      >
        <span>商务合作</span>
        <p class="bg-title2">COOPERATION</p>
      </div>
      <div class="footer-text">
        <div class="footer-center">
          <div class="footer-center-left">
            <div class="footer-center-left-title">商务合作请联系</div>
            <div class="footer-center-left-email">
              <div
                v-for="(item, index) in footer.email"
                :key="index"
              >
                <p class="p1">{{ item.name }}</p>
                <p class="p2">{{ item.title }}</p>
              </div>
            </div>
          </div>
          <div class="footer-center-right">
            <img
              :src="footer.qrCode.src"
              alt=""
            />
            <div>{{ footer.qrCode.label }}</div>
          </div>
        </div>
        <div class="footer-des">
          {{ footer.copyright.copyright }}
          <a
            :href="footer.copyright.ipc_link"
            target="_blank"
          >{{
            footer.copyright.icp_number
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from 'vue'
import { baseService, siteConfigService } from '@/api/api.js'
import { useRoute, useRouter } from 'vue-router'
export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const menu = ref(false)
    const nav = reactive({
      itemList: [
        { label: '首页', target: 'header' },
        { label: '公司介绍', target: 'target1' },
        { label: '品牌解决方案', target: 'target2' },
        { label: '资质认证', target: 'target3' },
        { label: '商务合作', target: 'footer' }
      ]
    })
    const footer = reactive({
      email: [],
      qrCode: { label: '', src: '' },
      copyright: {}
    })
    const isFixed = ref(false)
    const navTarget = ref('')
    const footerRef = ref(null)
    const isHoverSwitch = ref(false) //是否悬浮在导航菜单展开按钮上
    onMounted(() => {
      watchSroll()
      getEmail()
      getQrCode()
      getCopyRight()
      window.addEventListener('scroll', watchSroll)
    })
    const watchSroll = () => {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 50) {
        isFixed.value = true
      } else {
        isFixed.value = false
      }
    }
    const menuActiveClick = () => {
      //点击导航按钮
      menu.value = !menu.value
    }
    const navClick = (target) => {
      //点击导航菜单
      if (target == 'header') {
        backTop()
      } else if (target == 'footer') {
        setTimeout(() => {
          window.scrollTo(0, footerRef.value.offsetTop)
        })
      } else {
        navTarget.value = target
      }
    }
    const getEmail = () => {
      //商务合作 http://gw.huonu.local/website/v1/base/list?websiteId=process.env.VUE_APP_WEBSITED_ID&termCode=others&pageCode=business_cooperation&status=2
      baseService({
        websiteId: process.env.VUE_APP_WEBSITED_ID,
        termCode: 'others',
        pageCode: 'business_cooperation',
        status: 2
      }).then((res) => {
        if (res && res.code == 200 && res.data && res.data.records) {
          footer.email = res.data.records
        }
      })
    }
    const getQrCode = () => {
      //关注我们微信二维码：http://gw.huonu.local/website/v1/base/list?websiteId=process.env.VUE_APP_WEBSITED_ID&termCode=others&pageCode=follow_us&status=2
      baseService({
        websiteId: process.env.VUE_APP_WEBSITED_ID,
        termCode: 'others',
        pageCode: 'follow_us',
        status: 2
      }).then((res) => {
        if (res && res.code == 200 && res.data && res.data.records) {
          footer.qrCode.label = res.data.records[0].name
          footer.qrCode.src = res.data.records[0].iconUrl
        }
      })
    }
    const getCopyRight = () => {
      //http://gw.huonu.local/website/v1/site/config/get?id=process.env.VUE_APP_WEBSITED_ID
      siteConfigService({
        id: process.env.VUE_APP_WEBSITED_ID
      }).then((res) => {
        if (res && res.data) {
          footer.copyright = JSON.parse(res.data)
        }
      })
    }
    const backTop = () => {
      //回到顶部
      let top = document.documentElement.scrollTop || document.body.scrollTop //获取点击时页面的滚动条纵坐标位置
      const timeTop = setInterval(() => {
        top -= 50
        document.body.scrollTop
          ? (document.body.scrollTop = top)
          : (document.documentElement.scrollTop = top) //一次减50往上滑动
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 5) //定时调用函数使其更顺滑
    }
    const toUrl = () => {
      //回到顶部
      router.push('/en')
      menu.value = false
    }
    return {
      route,
      isFixed,
      menu,
      nav,
      menuActiveClick,
      navClick,
      footer,
      navTarget,
      footerRef,
      isHoverSwitch,
      toUrl
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
